@import './styles/variables';

.stats{
	display: flex;
	justify-content: space-between;
	align-items: center;
	.yes-no{
		color: $pop;
		display: inline-block;
	}
	> span {
		font-size: 0.8em;
		line-height: 1.1;
		display: inline-block;
		padding-right: 4px;
	}
	.percent{
	}
}