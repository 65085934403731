@import "./styles/variables";

.multiple-tags{
	position: fixed;
	z-indeX: 100;
	top: 20px;
	left: calc(50% - 40vw);
	background-color: $bg;
	border: 1px dotted $pop;
	border-radius: 2px;
	.close{
		position: absolute;
		top: 0px;
		right: 0px;
		padding: 10px;
		cursor: pointer;
	}
	&.open{
		padding: 20px;
		width: 80vw;
		.selected-tags{
			li{ 
				font-size: 1.5rem;
				&:before{
					content: ", ";
				}
				&:first-of-type:before{
					content: "";
				}
				display: inline-block;
			}
		}
		.btns{
			padding-top: 10px;
			select,
			a {
				display: inline-block;
				font-size: 0.9rem;
				margin: 0px 10px 10px 0px;
				padding: 5px 10px 4px;
				border-radius: 10px;
				color: $pop;
				background-color: $gray;
				text-decoration: none;
				&.active,
				&:hover{
					background-color: $pop;
					color: $bg;
				}
				i {
					display: inline-block;
					width: 15px;
					height: 15px;
					border-color: $pop;
					background-color: $pop;
					position: relative;
					top: 2px;
				}
				&:hover{
					i{
						border-color: $bg;
					}
					svg{
						path{
							fill: $bg;
						}
					}
				}
				svg{
					width: 15px;
					height: 15px;
					margin-right: 5px;
					vertical-align: middle;
					display: inline;
					path{
						fill: $pop;
					}
				}
			}
		}
	}
}
@media (max-width: $phone-width){
	.multiple-tags{
		top: auto;
		bottom: 10px;
		&.open{
			left: 0px;
			width: 100vw;
			bottom: 0px;;
		}
	}
}