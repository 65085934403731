@import './styles/mixins';
.see{
  text-align: center;
  padding-top: 20px;
  .groups-wrap{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    padding-bottom: 50px;
    @extend .tag-width;
    margin: 0 auto;
    label{
      padding-right: 10px;
      display: flex;
      align-items: center;
      height: 18px;
    }
    .plus{
      position: relative;
      top: 1px;
      padding: 0px 0px 0px 0px;
      font-size: 2em;
    }
    .tag-input{
      margin-bottom: 0;
    }
    &.open{
      align-items: center;
      .groups{
        display: none;
      }
    }
    .groups{
      flex-grow: 1;
      width: 100%;
      ul{
        display: flex;
        overflow-x: auto;
        padding-bottom: 10px;
        align-items: center;
        padding-top: 4px;
      }
      li{
        display: inline-block;
        padding-left: 0px;
        flex: 0 0 auto;
        line-height: 1;
        .tag{
          padding: 0px 10px 0 0;
          .stats-wrap{
            display: none !important;
          }
          a.a-open{
            display: none;
          }
          a.content{
            &::after{
              content: ', ';
            }
            padding: 0px;
            margin: 0px;
            .tag-tag{
              font-size: 1em;
            }
          }
        }
        &:last-child{
          .tag{
            a.content{
              &::after{
                content: '';
              }
            }
          }
        }
      }
    }
  }
  h1{
    width: 700px;
    max-width: 100%;
    margin: 0 auto 35px;
    .go-open{
      padding-left: 15px;
      .plus{
        border: 1px dotted $gray-md;
        border-radius: 100%;
        display: inline-block;
        padding: 3px 5px 0px 4px;
        line-height: 1;
      }
    }
    &.open{
      .go-open{
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        .plus{
          transform: rotate(45deg);
        }
      }
    }
    .tag-input-wrap {
      max-width: 300px;
      margin: 0 10px;
      .open-icon{
        right: 15px;
      }
      .tag-input__value-container{
        border-radius: 25px;
        border: 1px dotted #ccc;
        padding: 0px 20px;
      }
    } 
    .tag{
      display: inline-block;
      padding: 9px 20px 2px 20px;
      border-radius: 25px;
      margin: 0px 5px 10px;
      border: 1px dotted #ccc;
      position: relative;
      .remove{
        position: absolute;
        top: -4px;
        right: -7px;
        font-size: 18px;
        color: $pop;
        text-decoration: none;
        border: 1px dotted #ccc;
        border-radius: 100%;
        background-color: $bg;
        padding: 2px 5px 0px;
        line-height: 1;
      }
      &.img{
        padding: 0px;
      }
    }
    video{
      border-radius: 25px;
    }
    .link,
    .image{
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      img{
        display: inline-block;
        width: 100%;
        max-width: 100vw;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
      }
      > .caption{
        padding-top: 5px;
        font-size: 0.4em;
        color: #999;
      }
    }
    .link{
      img{
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
      }

    }
  }
  .tag-input{
    @extend .tag-width;
    margin: 0 auto;
    margin-bottom: 0px;
  }
  .add-tag{
    display: flex;
    margin: 0 auto 25px;
    @extend .tag-width;
    a.plus{
      padding: 0 0px 10px;
      font-size: 2em;
    }
  }
  p{
    max-width: 400px;
    margin: 0 auto;
  }
  .export{
    border: 1px solid $text-main;
    text-align: left;
    max-width: 400px;
    padding: 0px 15px 5px;
    margin: 0 auto;
    margin-bottom: 30px;
    li{
      padding-bottom: 10px;
    }
    input{
      background-color: transparent;
      color: $text-main;
      border: none;
      border-bottom: 1px solid $text-main;
      box-shadow: none;
    }
    a{
      text-decoration: none;
      margin-left: 10px;
    }
  }
  ul.tags{
    // if you're the only one and you said no, hide it
    .tag .a-open{
      display: none;
    }
  }
}
.App:not(.stats-on) .see{
  ul.tags{
    // if you're the only one and you said no, hide it
    .tag.neg,
    .tag.no.ack{
      display: none;
    }
  }
}
@media(max-width: $phone-width){
  .see{
    padding-top: 0;
    .add-tag{
      padding: 0px 10px 0px 0px;
    }
    .groups-wrap{
      padding: 0px 0px 40px;
    }
    > ul.tags{
      padding: 0px 0px;
    }
  }
}
