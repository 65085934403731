@import "./styles/variables";

.my-ic-list {
	li.persp{
		padding-bottom: 10px;
		h3{
			font-size: 1em;
		}
		a{
			text-decoration: none;
		}
		a.x,
		.label-wrap{
			display: none;
		}
		&.metamask{
			> a:first-of-type{
				position: relative;
				display: inline-block;
				padding-right: 20px;
				&:after{
					content: '';
					display: inline-block;
					width: 15px;
					height: 15px;
					position: absolute;
					right: 0px;
					background: url(./assets/metamask.png) right center no-repeat;
					background-size: auto 100%;
				}
			}
		}
		&.open{
			padding: 15px;
			border-left: 1px solid $pop;
			background-color: $gray;
			margin-bottom: 15px;
			position: relative;
			a.icon{
				position: absolute;
				top: 10px;
				right: 10px;
			}
			a.x{
				margin-top: 20px;
				display: block;
				text-align: right;
			}
			.label-wrap{
				display: block;
			}
			.addr.hint{
				font-size: 0.6em;
			}
		}
	}
	.perspectives{
		p{
			font-size: 0.9em;
			margin: 0px 0 3px 0;
			a {
				color: $pop;
			}
		}
	}

}