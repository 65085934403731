@import "./styles/mixins";

.create-db{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  flex-direction: column;
  text-align: center;
  .tag-input{
    width: 400px;
  }
  ul {
    @extend .tag-width;
    li{
      list-style: disc;
      padding-bottom: 15px;
    }
  }
  .flex{
    display: flex;
    justify-content: center;
  }
  a.plus{
    font-size: 30px;;
  }
  .more{
    margin-top: 50px;
  }
  .start{
    margin: 20px 0 0 0;
  }
}
.db-info{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}