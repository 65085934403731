@import "./styles/variables";
.App {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  // min-height: 100vh;
  .main {
    position: relative;
    .logo-wrap{
      font-size: 1.3rem;
      line-height: 1;
      height: 40px;
      display: flex;
      align-items: center;
      position: fixed;
      top: 4px;
      width: 40px;
      left: calc(50% - 20px);
      z-index: 2;
      display: none;
      a.logo{
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url(./assets/logo.svg) center center no-repeat;
        background-size: 130%;
      }
    }
    nav{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 15px;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      // margin-bottom: 10px;
      // border-bottom: 1px dotted $white;
      a{
        text-decoration: none;
      }
      .right-nav {
        display: flex;
        align-items: center;
        * {
          margin-left: 10px;
        }
      }
    }
    > .content{
      margin-top: 55px;

    }
  }
  .search-input{
    position: fixed;
    right: 45px;
    top: 15px;
    z-index: 5;
    &.open{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      a.fa{
        color: $pop;
      }
      .tag-input-wrap{
        margin-top: 10px;
        width: calc(100vw - 90px);
        background-color: $bg;
      }
    }
  }
  .location{
    transition: all 0.3s ease-in-out;
    position: fixed;
    top: 15px;
    left: 48px;
    width: calc(100% - 92px);
    padding: 0 15px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    a{
      text-decoration: none;
    }
    .breadcrumb{
      display: none;
      flex-grow: 1;
      padding-left: 5px;
      font-size: 0.8em;
      .bc{
        width: 100%;
        overflow-x: auto;
      }
      a {
        &:before{
          content: "  >  ";
          margin-left: 4px;
          margin-right: 3px;
          top: 1px;
          position: relative;
        }
      }
    }
  }
  .humburger{
    position: fixed;
    left: 0px;
    top: 1px;
  }
  .recent-activity{
    position: fixed;
    bottom: 0;
    right: 0px;
    background-color: $bg;
    border: 1px solid $white;
    z-index: 20;
    display: none;
    .tag{
      .a-open{
        display: none;
      }
    }
  }
  &.not-mine{
    a.a-open,
    a.plus:not(.always){
      // display: none;
    }

  }
  &.something-is-happening.uploading,
  &.something-is-happening.export{
    background-image: url(./assets/happening.gif);
    color: black;
  }
  &.stats-on{
    .help{
      display: inline;
    }
    .recent-activity{
      display: block;
    }
  }
  .user-page{
    width: 0px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  &.perspectives{
    .humburger{
      display: none !important;
    }
    .location{
      left: 0px;
      justify-content: flex-start;
      .breadcrumb{
        display: none;
      }
    }
    flex-direction: row;
    .user-page{
      min-height: 100vh;
      width: 250px;
      .section{
        padding-top: 50px;
        border: none;
      }
    }
    .main{
      padding: 0 0 0 15px;
      margin: 0;
      border-left: 1px solid #fff;
      .main-content{
        padding: 0;
      }
    }
  }
}
.ipfs-peers{
  background: url(./assets/ipfs.png) left center no-repeat;
  padding-left: 26px;
  background-size: 20px;
  padding-top: 5px;
}
.db-name{
  display: flex;
  align-items: center;
  flex-direction: column;
  svg{
    margin-right: 10px;
  }
}

.entry-points{
  font-size: 1.6em;
  line-height: 1.3;
}


@media (max-width: $phone-width) {
  .App{
    .main{
      .humburger{
        top: 0px;
        left: 0px;
        display: block;
        width: 45px;
        padding-bottom: 10px;
      }
      nav {
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
      }
    }
    .location{
      font-size: 0.85em;
      padding-left: 0px; 
    }
    &.perspectives{
      .user-page{
        width: 75vw;
      }
      .main{
        width: 25vw;
      }
      .location{
        left: 15px;
      }
    }
  }
}