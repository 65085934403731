@import 'styles/variables';
.uploader{
	// border: 2px dotted white; 
	// padding: 30px 15px;
	p{
		text-align: center !important;
	}
	&.uploading,
	&.active{
		background-color: $pop;
		color: black;
		border-color: black;
	}
	&.uploading{
		color: white;
		border-color: white;
	}
}