@import "./styles/variables";

.user-page{
	position: relative;
	.close{
		position: absolute;
		top: 10px;
		right: 10px;
		font-size: 1.5em;
		background: url(./assets/x.svg) center center no-repeat #fff;
		width: 50px;
		height: 50px;
		z-index: 100; 
		filter: invert(100%);
	}
	.icon {
		text-decoration: none;
		display: inline-block;
		padding: 0px 5px;
	}
	input[type="text"]{
		width: 100%;
		margin-bottom: 10px;
	}
	.possible-server-info{
		margin-top: 10px;
	}
	.add-remote-server{
		.options{
			font-size: 0.8em;
			a{
				color: $pop;
			}
		}
	}
	.private{
		margin-top: 15px;
	}
	.current-persp,
	.perspectives{
		p{
			font-size: 0.9em;
			margin: 0px 0 3px 0;
			a {
				color: $pop;
			}
		}
	}
	.current-persp{
		padding-bottom: 10px;
		border-bottom: 1px solid $white;
		margin-bottom: 50px;

	}
}
.user-page-modal{
	z-index: 2;
	position: fixed;
	top: 80px;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0,0,0,0.9);
	display: flex;
	justify-content: flex-start;
	text-align: left;
	.user-page{
		width: 100%;
		max-width: 350px;
		margin-left: 15px;
	}
}

@media (max-width: $phone-width) {
	.user-page-modal{
		top: 80px;
		padding: 0px 15px;
		.user-page{
			max-width: 100%;
			margin-left: 0;
		}
	}
}