@import "./styles/variables";
@import "./styles/mixins";

.tag{
	padding: 0px 0 10px;
	display: flex;
	align-items: center;
	flex-direction: column;
	.top-wrap{
		display: flex;;
		align-items: center;
		width: 100%;
		a{
			padding: 0px 5px;
			margin: 0 5px;
			display: inline-block;
			text-decoration: none;
			&.yes{
				@extend .yes-icon;
			}
			&.no{
				padding: 0;
				height: 25px;
				svg{
					width: 25px;
					height: 25px;
					path{
						fill: $gray-md;
					}
				}
				&:hover{
					svg path {
						fill: $pop;
					}
				}
			}
		}
	}
	.content{
		flex-grow: 1;
		text-align: left;
		position: relative;
		.tag-tag{
			font-size: 1.1em;
			line-height: 1.2;
		}
		.img{
			height: 100px;
			width: 150px;
			display: inline-block;
			background-position: left center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}
	.check-wrap{
		position: relative;
		padding-right: 15px;
		padding-top: 0;
		padding-left: 8px;
		&:before{
			content: '';
			position: absolute;
			top: 5px;
			left: 11px;
			width: 10px; 
			height: 10px;
			border-radius: 100%;
			border: 1px solid $pop;
			z-index: 2;
		}
		input[type="checkbox"]{
			opacity: 0;
			position: relative;
			z-index: 3;
			width: 15px;
		}
		&.selected{
			&:before{
				background-color: opacify($pop, 0.5);
			}
		}
	}
	&.yes{
		.check-wrap{
			&:before{
				background-color: $pop;
			}
		}
	}
	a.a-open{
		position: relative;
		&:before{
			line-height: 1;
			content: '⌄';
			font-size: 1.5em;
			opacity: 1;
			position: relative;
			top: -4px;
		}
	}
	a.add,
	a.yes,
	a.no{
		display: none;
	}
	&.yes{
		// a.a-open{
		// 	&:before{
		// 		content: '+';
		// 		opacity: 1;
		// 	}
		// }
		a.yes{
			@extend .yes-icon-active;
		}
	}
	&.no{
		// a.a-open{
		// 	&:before{
		// 		content: '-';
		// 		opacity: 1;
		// 	}
		// }
		a.no{
			background-color: $pop;
			svg{
				path{
					fill: $black;
				}
			}
			// border-bottom: 2px solid $pop;
		}
	}
	&.open{
		a.a-open{
			&::before{
				top: 8px;
				content: '⌃';
			}
		}
		a.add,
		a.no,
		a.yes{
			display: block;
		}
		a.add{
			font-size: 1.5em;
			line-height: 1;
			color: $pop;
			border-radius: 100%;
			border: 1px dotted $gray-md;
			padding: 3px 0 0 0;
			width: 25px;
			display: inline-block;
			height: 25px;
		}
	}
	.stats-wrap{
		padding: 0px 70px;
		margin-top: 5px;
		width: 100%;
		display: none;
	}
	&.ack.open{
		.stats-wrap{
			display: block;
		}
	}
}

.stats-on{
	.tag{
		.stats-wrap{
			display: block;
		}
	}
}
