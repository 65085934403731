@import "./styles/variables";

.sidebar{
	flex-grow: 1;
	// grid-row: span 2;
	// min-height: 100vh;
	// padding: 15px;
	// background-color: $gray;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	// align-self: stretch;
	padding-left: 20px;
	.db-name{
		text-decoration: none;
		
	}
	.user-info{
    display: flex;
    align-items: center;

	}
  .db-name-wrap{
    display: flex;
    align-items: center;
		.db-name{
			max-width: 200px;
      flex-direction: row;
			svg{
				width: 30px;
				height: 30px;
			}
			> span{
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				display: block;
				// direction: rtl;
				&.icfs-name{
					// padding-left: 3px;
					// &:before{
					// 	position: relative;
					// 	content: ' > ';
					// 	margin-left: 3px;
					// 	margin-right: 3px;
					// 	top: -2px;
					// 	font-size: 1rem;
					// }
					display: inline-block;
				}
			}
		}
    .links,
    .stats-wrap{
      display: none;
    }
  }
  .search{
		> a {
			margin-right: 10px;
		}
    display: flex;
    align-items: center;
    .tag-input{
      width: 200px;
      display: none;
    }
    &.open{
      .tag-input{
        display: block;
      }
    }
  }
	.stats{
	}
	.tabs{
		display: flex;
		margin-bottom: 10px;
		> a {
			flex: 1;
			text-align: center;
			text-decoration: none;
			padding: 5px 0;
			&.active{
				border-bottom: 1px solid $pop;
			}
			&.lens{
				font-size: 40px;
			}
		}
	}

	.filters{
		margin-left: auto;
		> div {
			display: none;
		}
	}
	.tab-wrap{
		flex-grow: 1;
		.db-name-wrap{
			margin-bottom: 10px;
		}
	}
	.recent{
		font-size: 0.8em;
		padding-top: 10px;
		li{
			padding: 0px 0 5px;
			line-height: 1.1;
		}
		a{
			color: $gray-md;
			text-decoration: none;
			&:hover{
				text-decoration: underline;
			}
		}
		.yes-no{
			color: $pop;
			font-size: 0.9rem;
		}
	}

	.bottom{
		display: flex;
		justify-content: space-between;
		a{
			text-decoration: none;
		}
	}
}
.App.page- .sidebar{
	.sort{
		display: none;
	}
}


@media (hover: hover) {
	// nav:hover .sidebar{
	// 	.db-name-wrap{
	// 		position: relative;
	// 		.stats-wrap{
	// 			position: absolute;
	// 			display: flex;
	// 			padding-top: 5px;
	// 			top: 20px;
	// 			lefT: 0px;
	// 			width: 150px;
	// 			align-items: center;
	// 			justify-content: flex;
	// 			.stats{
	// 				padding-left: 10px;
	// 				flex-grow: 1;
	// 			}
	// 		}
	// 		.links{
	// 			display: flex;
	// 			position: absolute;
	// 			top: 55px;
	// 			lefT: 0px;
	// 			width: 130px;
	// 			&:before{
	// 				content: 'linked ICs:  ';
	// 				font-size: 0.8em;
	// 				padding-right: 5px;
	// 				padding-top: 2px;
	// 			}
	// 		}
	// 	}
	// }
}

.stats-on{
	.sidebar{
		.filters{
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			.label{
				display: none;
			}
			> * {
				margin: 0 0px;
			}
			> div {
				display: block;
			}
			.ic-stats{
				display: flex;
				justify-content: flex-end;
				align-items: center;
				padding-top: 10px;
				.stats-wrap{
					margin-top: 2px;
				}
				.links{
					font-size: 0.8em;
					position: relative;
					svg{
						margin: 0 4px;
					}
					.fa{
						font-size: 15px;;
						height: 0px;
						margin-right: 5px;

					}
				}
			}
		}
	}
}

@media (max-width: $phone-width) {
	nav{
		position: relative;
	}
	.sidebar{
		padding-left: 0;
		flex-wrap: wrap;
		.db-name-wrap{
			width: 100%;
			justify-content: space-between;
			.db-name{
				max-width: 50vw;
			}
			.stats-wrap{
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
				.fa-cog{
					margin-left: 15px;

				}
				.stats{
					> span{
						height: 30px;
						padding-left: 10px;
						&:first-child{
							padding-top: 5px;
						}
					}
				}
			}
		}
		.breadcrumb{
			font-size: 13px;;
			width: 100%;
			a{
				display: block;
				padding-left: 10px;
				padding-top: 5px;
			}
		}
		.filters{
			position: fixed;
			right: 15px;
			top: 15px;
			z-index: 3;
		}
	}
}