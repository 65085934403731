@import "styles/variables";

.tag-input-wrap{
  width: 100%;
  position: relative;
  .open-icon{
    right: 10px;
    top: 10px;
    color: $pop;
    opacity: 0.7;
    z-index: 3;
  }
}
.tag-input{
  text-align: left;
  .tag-input__input,
  .tag-input__single-value,
  .tag-input__control{
    border-radius: 0px;
    background-color: transparent;
    font-weight: normal;
    color: $text-main;
    border-color: transparent !important;
    box-shadow: none;
    min-height: auto;
    input[type="text"]{
      width: 100% !important;
    }
  }
  .tag-input__input,
  .tag-input__value-container > div[class^="css-"]{
    width: 100%;
  }
  .tag-input__menu{
    background-color: $bg;
    border-radius: 0px;
    color: $text-main;
    margin-top: 0;
    font-size: 0.9em;
  }
  .tag-input__menu-list{
    padding: 0px;
  }
  .tag-input__option{
    outline: none;
    border-bottom: 1px dotted $pop;
    text-align: left;
    padding: 8px 12px 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .tag-input__option--is-focused{
    color: $bg;
    background-color: $pop;
  }
  .tag-input__menu-notice--no-options{
    padding: 0px;
  }
  .tag-input__value-container{
    padding-bottom: 0;
    border-bottom: 1px solid $pop;
  }
  .tag-input__dropdown-indicator,
  .tag-input__indicator-separator{
    display: none;
  }
}