@import "styles/mixins";

.entry-points{
	padding-top: 30px;

	.tags{
		padding-top: 30px;
		.stats-wrap,
		a.a-open{
			display: none;
		}
	}
	.add-something{
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 1;
		.tag-input{
			text-align: center;
			flex-grow: 1;
		}
		a.plus{
			font-size: 3em;
		}
	}

	.no-tags{
		@extend .tag-width;
		margin: 0px auto;

	}
}
@media(max-width: $phone-width){
	.entry-points{
		.add-something{
			&.open{
				padding-right: 15px;
			}
			a.plus{
				font-size: 2em;
			}
			.tag-input{
				font-size: 0.7em;
			}
			.open-icon{
				font-size: 0.8em;
				top: 6px;
				right: 5px;
			}
		}
	}
}