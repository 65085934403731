@import "styles/variables";
@import "styles/mixins";


@font-face {
  font-family: "FranklinGothicLTPro-Md";
  src: url('assets/fonts/FranklinGothicLTProMd/font.woff2') format('woff2'), url('assets/fonts/FranklinGothicLTProMd/font.woff') format('woff');
}
@font-face {
  font-family: "FranklinGothicLTPro-Bk";
  src: url('assets/fonts/FranklinGothicLTProBk/font.woff2') format('woff2'), url('assets/fonts/FranklinGothicLTProBk/font.woff') format('woff');
}

body {
  margin: 0;
  padding: 0;
  background-color: $bg;
  color: $text-main;
  font-family: "FranklinGothicLTPro-Md", sans-serif;
}
*{
  box-sizing: border-box;
}
.App{
  h1{
    font-size: 1.6rem;
    font-weight: bold;
    margin: 0;
  }
  h3{
    margin: 1em 0 0.3em;
  }
  
  a{
    // text-decoration: underline;
    color: $text-main;
    cursor: pointer;
    &.plus{
      text-decoration: none;
      color: $pop;
      position: relative;
      transition: all 500ms cubic-bezier(1.000, 0.000, 0.000, 1.000);
      height: 1em;
      width: 1em;
      text-align: center;
      display: inline-block;
      &.open{
        transform: rotate(765deg);
      }
    }
    &.pop,
    &.x{
      color: $pop;
      font-size: 0.8em;
    }
  }
  .color-pop{
    color: $pop;
  }
  .count{
    font-size: 0.8em;
  }
  .help{
    display: none;
  }
  
  select{
    border: none;
    // border-bottom: 1px solid $gray-md;
    background-color: transparent;
    color: $gray-md;
    padding: 3px 10px;
    outline: none;
    text-align: right;
  }
  ul{
    list-style: none;
    margin: 0 auto;
    padding: 0;
    li{
      .btn{
        margin-top: 10px;
      }
    }
  }
  ul.tags{
    display: flex;
    flex-direction: column;
    @extend .tag-width;
    li{
      flex: 1;
    }
    .tag{
      margin-bottom: 0px;
    }
  }
  .btn{
    border: 2px solid $pop;
    background-color: transparent;
    display: inline-block;
    text-decoration: none;
    color: $pop;
    padding: 8px 20px 4px;
    text-transform: uppercase;
    cursor: pointer;
    &.disabled{
      opacity: 0.5;
      cursor: not-allowed;
    }
    &:hover{
      background-color: $pop;
      color: $bg;
    }
  }
  
  input[type="text"]{
    background-color: transparent;
    color: $white;
    border: 1px dotted $pop;
    padding: 5px;
    &:focus{
      outline: none;
      box-shadow: none;
      border-style: solid;
    }
  }
  textarea{
    background-color: transparent;
    color: $white;
    border: 1px solid $pop;
    width: 100%;
    min-height: 150px;
    padding: 10px;
  }
  .hint{
    font-size: 0.8em;
    color: $gray-md;
  }
  .section{
    border: 1px solid white;
    padding: 15px;
    margin-bottom: 15px;
  }
  .inverse{
    background-color: $pop;
    color: $bg;
    a {
      color: $bg;
    }
    .btn{
      border-color: $bg;
      background-color: $pop;
      color: $bg;
      &:hover{
        background-color: $bg;
        color: $pop;
      }
    }
    .section{
      border-color: $bg;
      margin-bottom: 0;
    }
    .hint{
      color: $gray;
    }
  }
}
p.hint{
  margin: 0.5em 0;
}